import countries_json from '@/libs/countries.json';
import languages from '@/libs/languages.json';
import { isIOS } from './formats';
import store from '@/store'

export const getMotivationalPhrase = () => {
  const dayWeek = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];
  const motivational_phrases = [
    `En caso de que no te dieras cuenta hoy es ${dayWeek[new Date().getDay()]}`,
    "¿Ya hiciste ejercicio hoy?",
    "Siempre puedes empezar de nuevo",
    "Todo lo que puedes imaginar es real",
    "It always get better",
    "Hacer lo correcto trae paz y serenidad. Hazlo una y otra vez",
    "Hoy intenta hacer algo que crees que no puedes hacer",
    "La esperanza es el sueño del hombre despierto",
    "No cuentes los días, haz que los días cuenten!",
    "Keep going, Keep growing",
    "¿Ya te tomaste un café hoy?",
    "Si lo puedes soñar,  lo puedes lograr",
    "Baila como si estuvieras trabajando en home office",
    "Relájate un rato en lo que cargamos la página para ti",
    "We´re in this together",
    "Controla lo que puedes controlar y de lo demás relájate",
    "¿Ya meditaste hoy?",
    "Toma agua mientras cargamos la información por ti"
  ];

  const array_length = motivational_phrases.length - 1
  const index = Math.floor(Math.random() * array_length);

  return motivational_phrases[index]
}
export const getCountries = () => {
  const countries = store.getters['main_store/allCountries'];
  if (countries.length === 0) {
    store.commit('main_store/SET_COUNTRIES', countries_json)
    return countries_json;
  } else return countries;
};

export const getCountry = (code) => {
  const country = countries_json.find(item => item['alpha-2'] === code.toUpperCase());
  if (country) {
    return country.name;
  }
  return code;
};

export const getLanguage = (code) => {
  const language = languages.find(item => item.code === code);
  if (language) {
    return language.name;
  }
  return code;
}
export const print = async(orientation, filename, element, callback, format = 'pdf') => {
  element = element || document.body
  const noscript = document.getElementsByTagName('noscript')[0]
  if (noscript) noscript.remove()
  setTimeout(async () => {
    const margin = 15;
    const html2canvas = (await import('html2canvas')).default;
    html2canvas(element)
      .then(async (canvas) => {
        canvas.getContext('2d');
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        if (format === 'pdf') {
          const jsPdf = (await import('jspdf')).default;
          const pdf = new jsPdf(orientation, 'pt', [(canvas.width + (margin * 2)), (canvas.height + ((margin * 2)))]);
          pdf.addImage(imgData, 'JPG', margin, margin, canvas.width, canvas.height);
          pdf.save(`${filename}.pdf`);
          callback(true)
        } else {
          const a = document.createElement('a');
          a.href = imgData;
          a.download = `${filename}.png`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          callback(true)
        }
        const noscript = document.getElementsByTagName('noscript')[0]
        if (noscript === undefined) {
          const tag = document.createElement('noscript');
          const text = document.createTextNode("We're sorry but BrandMe Platform doesn't work properly without JavaScript enabled. Please enable it to continue.");
          tag.appendChild(text);
          const element = document.body;
          element.appendChild(tag);
        }
      });
  }, 100);
}

export const getTypeInfluencer = (category) => {
  const types = {
    'top-celebrity': 'Top Celebrity',
    'top-influencer-celebrity': 'Top Influencer / Celebridad',
    'microinfluencer': 'Micro-influencer',
    'macroinfluencer': 'Macro-influencer',
    'nanoinfluencer': 'Nanoinfluencer',
  }

  if (types[category]) return types[category]
  return ''
};

export const redirectLastRoute = (router) => {
  let last_routes = JSON.parse(localStorage.getItem('routes_visited'));
  if (last_routes) last_routes = last_routes.filter(i => (i.name !== 'view_proposal') && (i.name !== 'view_casting_call'))
  if (last_routes && last_routes.length > 0) {
    router.push({name: last_routes[last_routes.length - 1].name, params: last_routes[last_routes.length - 1].params});
    localStorage.removeItem('steps');
    localStorage.removeItem('stepperInfo');
  } else {
    router.push({name: 'home_search'});
  }
}
export const getUniqueIndex = () => {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2, 10);
  return `${timestamp}${random}`;
};

export const getModelsImagine = () => {
  return [
    {value: 21, category: 'anime_ia', name: 'Anime'},
    {value: 26, category: 'portrait_ia', name: 'Portrait'},
    {value: 29, category: 'realistic_ia', name: 'Realistic'},
    {value: 27, category: 'imagine_v1', name: 'Imagine V1'},
    {value: 28, category: 'imagine_v3', name: 'Imagine V3'},
    {value: 30, category: 'imagine_v4', name: 'Imagine V4'},
    {value: 31, category: 'imagine_v4_creative', name: 'Imagine V4 (Creative)'},
    {value: 32, category: 'imagine_v4_1', name: 'Imagine V4.1'},
    {value: 33, category: 'imagine_v5', name: 'Imagine V5'},
    {value: 34, category: 'anime_v5', name: 'Anime V5'},
    {value: 122, category: 'sdxl_1_0', name: 'SDXL 1.0'}
  ]
}

export const getArtStyleImagine = () => {
  return [
    {value: null, category: null, name: 'No Style'},
    {value: 1, category: 'cyberpunk_style_ia', name: 'Cyberpunk'},
    {value: 2, category: 'neon_style_ia', name: 'Neon'},
    {value: 3, category: 'minecraft_style_ia', name: 'Minecraft'},
    {value: 4, category: 'tattoo_style_ia', name: 'Tattoo'},
    {value: 5, category: 'disney_style_ia', name: 'Disney'}
  ]
}

export const downloadFile = (url) => {
  if (isIOS()) {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const splitted = url.split('/');
        link.download = splitted[splitted.length - 1];
        link.click();
      }
    };
    xhr.onerror = () => {
      if (xhr.status === 0)
        window.open(url, '_blank');
    };
    xhr.send();
  }
}
export const getImageInfluencer = (influencer) => {
  if (influencer.image_url && influencer.image_url.length > 0) {
    return influencer.image_url;
  } else {
    return influencer.profile_url && influencer.profile_url.length > 0 ? influencer.profile_url : (influencer.file_for_profile && influencer.file_for_profile.length > 0 ? influencer.file_for_profile : null);
  }
}
export const getTypeInfluencerTraduction = (category) => {
  const types = {
    'top-celebrity': 'influencerCategory.top-celebrity',
    'top-influencer-celebrity': 'influencerCategory.top-influencer-celebrity',
    'microinfluencer': 'influencerCategory.microinfluencer',
    'macroinfluencer': 'influencerCategory.macroinfluencer',
    'nanoinfluencer': 'influencerCategory.nanoinfluencer',
  };

  if (types[category]) return types[category];
  return '';
}
export const getTypeInfluencerNumber = (category) => {
  const types = {
    'top-celebrity': 1,
    'top-influencer-celebrity': 2,
    'macroinfluencer': 3,
    'microinfluencer': 4,
    'nanoinfluencer': 5,
  }

  if (types[category]) return types[category]
  return 6
}
export const getRandom = (min, max) => {
  return Math.floor((Math.random() * (max - min)) + min);
}
export const getAvaibleData = (obj) => {
  let index = 0
  for (const property in obj) {
    if (obj[property].value && obj[property].value !== 0) {
      return {"performance": property.slice(0,-1), "value": obj[property].value, index}
    } else if ((obj[property].media_ids !== undefined && obj[property].media_ids.length !== 0)) {
      return {"performance": property.slice(0,-1), "value": obj[property].media_ids, index}
    }
    index += 1;
  }
  return {"performance": 0, "value": 0, index}
}