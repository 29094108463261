<template>
    <b-sidebar
      v-model="show"
      @change="closeSider"
      right
      shadow
      backdrop
      header-class="container-title-sider"
      width="400px"
    >
      <template #header>
        <div>
          <span class="title-sider col-md-6 p-0">{{ $t("socialConnections") }}</span>
          <span class="ml-2 text-sider-why col-md-5 color-why" @click="show_sider_why = true">
            {{ $t("dashboard.whySider") }}
          </span>
          <feather-icon
            class="close-sider-network"
            icon="XIcon"
            size="20"
            @click="show = false"
          />
        </div>
      </template>
      <div class="p-1" v-if="!add_network_section && !form">
        <no-results v-if="networks.length === 0" :title="$t('creator.noNetTitle')" :subtitle="$t('creator.noNetSubtitle')" :show_button="false"/>
        <!-- SECTION OF NETWORKS CONNECTED TO ACCOUNT -->
        <div v-for="(network, index) in networks" :key="index" class="network-item-list-manage" :style="`border-left: 15px solid ${getColorV2(network.network, false)}`">
          <div :style="`background: ${getColorV2(network.network,false)}`" class="background-color-net d-none d-sm-flex">
            <b-img :src="getIconWhite(network.network)" fluid class="icon-profile-connection"/>
          </div>
          <div class="p-1 d-flex justify-content-between align-items-center w-100">
            <div class="div-user-size">
              {{ nameNetworks(network.network) }} <b-badge v-if="!isNetworkUnavaibleToConnect(network.network)" pill :class="network.connected ? 'success-pill-badge' : 'danger-pill-badge'">{{ network.connected ? $t("creator.verified"): $t("creator.notVerified") }}</b-badge>
              <p class="m-0 avenir-medium text-overflow">{{(network.name ? network.name : network.username)}}</p>
            </div>
            <div class="d-flex">
              <div class="button-icon-actions-nets" v-if="!network.connected || network.network === 'blog'" @click="editNetworkAction(network)">
                <feather-icon icon="EditIcon" class="icon-actions-nets"></feather-icon>
              </div>
              <div class="button-icon-actions-nets" @click="deleteNetwork(network.uuid)">
                <feather-icon icon="TrashIcon" class="icon-actions-nets"></feather-icon>
              </div>
            </div>
          </div>

        </div>
        <!-- ----------------------------- -->
      </div>
      <div class="overflow-hidden">
        <!-- FORM SECTION -->
        <div class="p-2" v-if="form">
          
          <div v-if="newNetwork.network !== 'blog' && newNetwork.network !== 'snapchat'">
            <p class="avenir-medium mb-05">{{ $t('connectWith') }}:</p>
  
            <v-facebook-login
              :app-id="facebook_app_id"
              v-model="user_face"
              :login-options="facebookOptions"
              :options="optionsButton"
              @login="getUserDataFacebook"
              @sdk-init="handleSdkInit"
              v-if="newNetwork.network === 'facebook'"
              class="w-100"
            >
              <template #login>
                {{ $t('initFacebook') }}
              </template>
            </v-facebook-login>
  
            <v-facebook-login
              :app-id="facebook_business_app_id"
              v-model="insta_face"
              :login-options="instagramOptions"
              :options="optionsButton"
              @login="getUserDataInstagram"
              @sdk-init="handleSdkInitInstagram"
              :key="network_to_link"
              class="w-100"
              v-if="newNetwork.network === 'instagram'"
            >
              <template #login>
                {{ $t('initFacebook') }}
              </template>
            </v-facebook-login>
  
            <consent-spotify :modalShow="modal_consent" :key="change_modal_consent" @loginSpotify="redirectSpotifyLogin"/>
  
            <div 
              class="network-item-button-oauth" 
              @click="redirectNetwork()" 
              v-if="newNetwork.network !== 'instagram' && newNetwork.network !== 'youtube' && newNetwork.network !== 'facebook'"
            >
              <div :style="`background: ${getColorV2(newNetwork.network)}`" class="background-color-net w-100">
                <b-img :src="getIconWhite(newNetwork.network)" fluid class="icon-twitter-profile-connection"/>
  
                {{ $t('continueWith') }} {{ nameNetworks(newNetwork.network, false) }}
              </div>
            </div>
  
            <b-button @click="youtubeLogin()" class="google-icon-button-oauth" v-if="newNetwork.network === 'youtube'">
              <b-img class="img-google" :src="require('@/assets/images/google/google.svg')"></b-img>
              <span>{{ $t('login.googleLogin') }}</span>
            </b-button>
            
            <div class="hr-oauth">
              <span>{{ $t('login.divider') }}</span>
            </div>
          </div>

          <validation-observer #default="{ invalid }" ref="newNetForm">
            <b-form class="col-12 p-0" @submit.prevent="add_network_section ? addNetwork() : updateNetwork()">
              <div 
                v-if="newNetwork.network === 'linkedin' || newNetwork.network === 'blog' || newNetwork.network === 'spotify'">
                <b-form-group >
                  <validation-provider rules="required">
                    <div class="d-flex justify-content-between size-label-inputs">
                      <label for="username">{{$t('manually')}}</label>
                      <feather-icon icon="HelpCircleIcon" size="18" v-b-toggle="'collapse'"></feather-icon>
                    </div>
                    <b-collapse id="collapse" class="collapse-extra-info mb-1">
                      <span>{{$t('dashboard.addConnectionExtraInfo')}}</span>
                    </b-collapse>
                    <b-input-group>
                      <b-form-input
                        maxlength="50"
                        :placeholder="$t('nameUserURL')"
                        size="lg"
                        v-model="newNetwork.name"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider rules="required">
                    <b-input-group>
                      <b-form-input
                        maxlength="75"
                        :placeholder="'URL'"
                        size="lg"
                        :disabled="!add_network_section"
                        v-model="newNetwork.username"
                        :state="urlStateExpression(newNetwork.username)"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider rules="required">
                    <cleave
                      v-model="newNetwork.followers"
                      :raw="false"
                      class="cleave-followers-add-network form-control"
                      :options="options_cleave.number"
                      :placeholder="$t('campaigns.followers')"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-else>
                <b-form-group>
                  <validation-provider rules="required">
                    <div class="d-flex justify-content-between size-label-inputs">
                      <label for="username">{{$t('manually')}}</label>
                      <feather-icon icon="HelpCircleIcon" size="18" v-b-toggle="'collapse'"></feather-icon>
                    </div>
                    <b-collapse id="collapse" class="collapse-extra-info mb-1">
                      <span>{{$t('dashboard.addConnectionExtraInfo')}}</span>
                    </b-collapse>
                    <b-input-group prepend="@">
                      <b-form-input
                        maxlength="50"
                        :placeholder="$t('nameUser')"
                        size="lg"
                        v-model="newNetwork.username"
                        :disabled="!add_network_section"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider rules="required">
                    <cleave
                      v-model="newNetwork.followers"
                      :raw="false"
                      class="cleave-followers-add-network form-control"
                      :options="options_cleave.number"
                      :placeholder="$t('campaigns.followers')"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="d-flex justify-content-end">
                <b-button
                  type="submit"
                  :disabled="getStateButtonSubmit(invalid)"
                  class="blue-button btn-position"
                  variant="blue-button"
                >
                  <b-icon :icon="add_network_section ? 'plus-circle' : 'arrow-repeat'" />
                  {{ add_network_section ? $t("settings.social.add") : $t("lists.update") }}</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <!-- ------------ -->

        <!-- BUTTONS TO ADD A NETWORK -->
        <div class="ml-3 pt-2" v-else-if="add_network_section">
          <b-row :hidden="form === true">
            <b-button
              v-for="index in net"
              :key="index"
              class="btn-network px-3 py-2 col-5"
              variant="btn-network"
              @click="
                form = true;
                newNetwork.network = index;
              "
              > 
                <b-avatar variant="ligth" size="42" class="d-flex align-items-center justify-content-center">
                  <b-img :src="getIconWhite(index)" :class="getIconColor(index)" fluid></b-img>
                </b-avatar>
              <span class="text-network-sider">{{
                capitalize(index)
              }}</span></b-button
            >
          </b-row>
        </div>
        <!-- ------------------------ -->
      </div>
      <template #footer>
        <div class="d-flex align-items-center p-1 border-top box-shadow-footer-sidebar" v-if="show_footer && !form">
          <b-button variant="blue-button" class="blue-button w-100" @click="add_network_section = true">{{ $t('buttonAddNetwork') }}</b-button>
        </div>
      </template>
      <sider-why
      class="open-sidebar-search"
      @close_siderbar_why="closeSiderWhy"
      :show_sider_why="show_sider_why"
      v-if="show_sider_why"
      />
    </b-sidebar>
</template>
  
<script>
import {
  BIcon,
  BSidebar,
  BButton,
  BAvatar,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BImg,
  BCollapse,
  VBToggle,
  BBadge,
} from "bootstrap-vue";
import { getColorV2, getIconWhite, getIconColor } from '@/libs/utils/icons'
import { urlStateExpression, isUrl, usernameByUrl, getUsernameUrlYoutube } from '@/libs/utils/urls'
import { capitalize, nameNetworks, isNetworkUnavaibleToConnect } from '@/libs/utils/formats';
import { getMotivationalPhrase } from '@/libs/utils/others';
import service from "@/services/others";
import social_service from "@/services/social";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import cleave from 'vue-cleave-component';
import VFacebookLogin from 'vue-facebook-login-component'

export default {
  name: "SiderNetwork",
  components: {
    ValidationProvider,
    ValidationObserver,
    BIcon,
    BInputGroup,
    BFormInput,
    BForm,
    BFormGroup,
    BRow,
    BSidebar,
    BButton,
    BAvatar,
    BImg,
    BCollapse,
    BBadge,
    cleave,
    VFacebookLogin,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    siderWhy: () => import('@/views/pages/proposals/stepper/siderWhy.vue'),
    ConsentSpotify: () => import('@/views/pages/account-setting/ConsentSpotify.vue'),

  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    show_sider_network: {
      type: Boolean,
    },
    networks: {
      type: Array,
      default: () => {
        return []
      }
    },
    is_adding: {
      type: Boolean,
      default: true,
    },
    show_footer: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      getIconColor,
      getIconWhite,
      isNetworkUnavaibleToConnect,
      nameNetworks,
      getColorV2,
      capitalize,
      urlStateExpression,
      isUrl,
      usernameByUrl,
      getUsernameUrlYoutube,
      add_network_section: this.is_adding,
      networkUpdate: {},
      show_sider_why: false,
      newNetwork: {
        network: "",
        username: "",
        is_channel: false,
      },
      net: [
        "instagram",
        "tiktok",
        "youtube",
        "twitter",
        "twitch",
        "facebook",
        "snapchat",
        "pinterest",
        "linkedin",
        "spotify",
        "blog",
      ],
      required,
      form: false,
      show: this.show_sider_network,
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      avatar: "",
      changeAvatar: null,
      // INSTA DATA OAUT
      facebook_business_app_id: process.env.VUE_APP_FACEBOOK_BUSINESS_APP_ID,
      instagramOptions: {
        scope: 'instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,business_management',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      optionsButton: {
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      },
      ig: {},
      scope_instagram: {},
      insta_face: {},
      network_to_link: '',
      // ----------
      // FACEBOOK DATA OAUTH
      facebook_app_id: process.env.VUE_APP_FACEBOOK_APP_ID,
      user_face: {},
      facebookOptions: {
        scope: 'email,user_link',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      fb: {},
      scope_facebook: {},
      // ----------
      interval_loading: null,
      modal_consent: false,
      change_modal_consent: false,
    };
  },
  methods: {
    handleSdkInit({ FB, scope }) {
      this.fb = FB
      this.scope_facebook = scope
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    getUserDataFacebook(data) {
      if (!data || !data.authResponse) return
      this.showLoader()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))

      social_service.save_token_facebook(form_data)
        .then(response => {
        
          this.hideLoader()
          this.scope_facebook.logout()
          if (response.code && response.code === 'accountNetwrokExists') {
            this.showToast(
              this.$t("socialErrorTitle"),
              this.$t("socialError"),
              "danger",
              "AlertIcon"
            );
          } else if (response.code) {
            this.showToast(
              this.$t("socialErrorTitle"),
              this.$t("tryAgain"),
              "danger",
              "AlertIcon"
            );
          } else if (response.new) {
            // this.getNetworks();
            this.showToast(
              this.$t("socialConnected"),
              this.$t("socialSuccess"),
              "success",
              "AlertIcon"
            );
          } else {
            this.showToast(
              this.$t("socialAlreadyConnected"),
              this.$t("socialAlreadySuccess"),
              "warning",
              "AlertIcon"
            );
          }
        })
      this.hideLoader()
    },

    getUserDataInstagram(data) {
      if (!data || !data.authResponse) return
      this.showLoader()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))
      this.showLoader()
      social_service.save_token_instagram(form_data)
        .then(() => {
          this.network_to_link = ''
          const self = this
          setTimeout(function () {
            // self.getNetworks();
            self.$vs.loading.close()
          }, 1000)
        })
    },
    showLoader() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hasChangeFile() {
      setTimeout(() => {
        this.avatar = URL.createObjectURL(this.changeAvatar);
      }, 200);
    },
    redirectNetwork() {
      if (this.newNetwork.network !== 'blog' && !this.newNetwork.connected) {
        localStorage.setItem('user_to_link', this.newNetwork.username)
        if (this.newNetwork.network === 'twitter') {
          this.redirectTwitter()
        } else if (this.newNetwork.network === 'tiktok') {
          this.redirectTiktokLogin();
        } else if (this.newNetwork.network === 'linkedin') {
          this.redirectLinkedinLogin()
        } else if (this.newNetwork.network === 'twitch') {
          this.redirectTwitchLogin();
        } else if (this.newNetwork.network === 'pinterest') {
          this.redirectPinterestLogin()
        } else if (this.newNetwork.network === 'spotify') {
          this.modal_consent = true
          this.change_modal_consent = !this.change_modal_consent
        }
      }
    },
    redirectTiktokLogin() {
      localStorage.setItem('network_code', 'tiktok')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_CLIENT_KEY_TIKTOK
      const redirect_uri = process.env.VUE_APP_TIKTOK_CALLBACK
      const url = `https://www.tiktok.com/v2/auth/authorize?client_key=${client_key}&scope=user.info.basic,video.list,user.info.profile,user.info.stats&response_type=code&state=${state}&redirect_uri=${redirect_uri}`
      window.location.href = url;
    },
    youtubeLogin() {
      localStorage.setItem('network_code', 'youtube')
      const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

      const options = {
        client_id: process.env.VUE_APP_GOOGLE_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_CALLBACK,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
        hl: 'en'
      };

      const qs = new URLSearchParams(options);

      window.location.href = `${rootUrl}?${qs.toString()}`
    },
    redirectTwitter() {
      this.showLoader()
      social_service.get_url_login_twitter()
        .then(response => {
          const url = response.url
          localStorage.setItem('token_twitter', response.temp_secret);
          window.location.href = url;
        })
    },
    redirectTwitchLogin() {
      localStorage.setItem('network_code', 'twitch')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_TWITCH_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'analytics:read:games+bits:read+channel:manage:schedule+channel:manage:videos+channel:read:editors+channel:read:goals+channel:read:hype_train+channel:read:polls+channel:read:predictions+channel:read:redemptions+channel:read:subscriptions+moderation:read+user:read:email+user:read:follows+user:read:subscriptions'
      const url = `https://id.twitch.tv/oauth2/authorize?force_verify=true&response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectPinterestLogin() {
      localStorage.setItem('network_code', 'pinterest')
      const client_id = process.env.VUE_APP_PINTEREST_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read,catalogs:read,catalogs:write'
      const state = Math.random().toString(36).substring(2);
      const url = `https://www.pinterest.com.mx/oauth/?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectLinkedinLogin() {
      localStorage.setItem('network_code', 'linkedin')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_LINKEDIN_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK

      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile+r_emailaddress+w_member_social`
      window.location.href = url
    },
    redirectSpotifyLogin() {
      localStorage.setItem('network_code', 'spotify')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_SPOTIFY_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK
      const scope = 'user-read-private user-read-email' // user-follow-read user-read-currently-playing user-top-read user-library-read
      const url = `https://accounts.spotify.com/authorize?response_type=code&client_id=${client_key}&scope=${scope}&redirect_uri=${redirect_uri}&state=${state}`
      window.location.href = url;
    },
    handleSdkInitInstagram({ FB, scope}) {
      this.ig = FB
      this.scope_instagram = scope
    },
    netWithoutFollow() {
      if (this.networkUpdate.network !== 'blog' && this.networkUpdate.network !== 'spotify' && this.networkUpdate.network !== 'linkedin') return true
      else return false
    },
    showInputFile() {
      this.$refs["input-file"].$refs["input"].click();
    },
    updateNetwork() {
      const formData = new FormData();
      formData.append("username", this.newNetwork.username);
      formData.append("followers", this.newNetwork.followers.replaceAll(',', ''));

      if (this.newNetwork.network !== 'blog' && this.newNetwork.network !== 'spotify' && this.newNetwork.network !== 'linkedin') {
        formData.append("is_channel", this.newNetwork.is_channel);
      } else {
        formData.append("name", this.newNetwork.name);
      }
      formData.append("network", this.newNetwork.network);
      
      service.addSocialNetwork(formData).then((response) => {
        this.$emit("updated_network_edit", response.user_network);
        this.show = false;
        if (response)
          this.showToast(
            this.$t("socialUpdated"),
            this.$t("updateNetwork"),
            "success",
            "ShuffleIcon"
          );
      });
    },
    editNetworkAction(network) {
      this.newNetwork = {...network};
      this.form = true;
    },
    deleteNetwork(network_uuid) {
      const self = this
      self.$swal({
        title: this.$t('confirmDeleteNetworkTitle'),
        text: this.$t('confirmDeleteNetwork'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('msjConfirmNetwork'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {

          service.removeSocialNetwork(network_uuid).then(() => {
            this.showToast(
              this.$t("socialDeleted"),
              this.$t("socialDeleteddata"),
              "danger",
              "Trash2Icon"
            );
            this.$emit('delete_network', network_uuid) 
          });
        }
      })
    },
    closeSiderWhy() {
      this.show_sider_why = false
    },
    getStateButtonSubmit(required) {
      if (this.newNetwork.network === 'blog' || this.newNetwork.network === 'spotify' || this.newNetwork.network === 'linkedin') return !urlStateExpression(this.newNetwork.username) || this.newNetwork.name.length === 0 || required || parseInt(this.newNetwork.followers) <= 0
      return required || parseInt(this.newNetwork.followers) <= 0
    },
    closeSider() {
      this.$emit("close_siderbar");
    },
    resetForm() {
      this.newNetwork = {
        network: "",
        username: "",
        is_channel: false,
        followers: "",
      };
    },
    showToast(
      title,
      text,
      variant,
      icon,
      position = "top-right"
    ) {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position,
          props: {
            title,
            icon,
            variant,
            text,
          }
        })
      })
    },
    addNetwork() {
      let username = "";

      this.$refs.newNetForm.validate().then((valid) => {
        if (valid) {
          if (isUrl(this.newNetwork.username)) {
            if (this.newNetwork.network === "instagram") {
              username = usernameByUrl(
                this.newNetwork.username,
                "instagram.com/"
              );
            } else if (this.newNetwork.network === "tiktok") {
              username = usernameByUrl(
                this.newNetwork.username,
                "tiktok.com/@"
              );
            } else if (this.newNetwork.network === "pinterest") {
              username = usernameByUrl(
                this.newNetwork.username,
                "pinterest.com/"
              );
            } else if (this.newNetwork.network === "twitter") {
              username = usernameByUrl(
                this.newNetwork.username,
                "twitter.com/"
              );
            } else if (this.newNetwork.network === "snapchat") {
              username = usernameByUrl(
                this.newNetwork.username,
                "snapchat.com/add/"
              );
            } else if (this.newNetwork.network === "twitch") {
              username = usernameByUrl(
                this.newNetwork.username,
                "twitch.tv/"
              );
            } else if (this.newNetwork.network === "facebook") {
              username = usernameByUrl(
                this.newNetwork.username,
                "facebook.com/"
              );
            } else if (this.newNetwork.network === "youtube") {
              const result = getUsernameUrlYoutube(
                this.newNetwork.username
              );
              username = result[0];
              this.newNetwork.is_channel = result[1];
            } else if (this.newNetwork.network === "blog") {
              username = this.newNetwork.username
            } else if (this.newNetwork.network === "linkedin") {
              username = this.newNetwork.username
            } else if (this.newNetwork.network === "spotify") {
              username = this.newNetwork.username
            }
            
            if (!username) {
              this.showToast(
                "Error",
                this.$t("errorUrl"),
                "danger",
                "AlertIcon"
              );
              return;
            }
          }
          const obj_new_network = {...this.newNetwork}
          if (username) obj_new_network.username = username
          if (obj_new_network.followers) obj_new_network.followers = parseInt(obj_new_network.followers.split(',').join(''));
          service.addSocialNetwork(obj_new_network).then((response) => {
            this.closeSider();
            if (response.code && response.code === "accountNetwrokExists") {
              this.showToast(
                this.$t("socialErrorTitle"),
                this.$t("socialError"),
                "danger"
              );
            } else if (response.new) {
              this.resetForm();
              this.$emit("updated_network", response.user_network);
              this.showToast(
                this.$t("socialAdd"),
                this.$t("socialSuccess"),
                "success",
                "CheckCircleIcon"
              );
            } else {
              this.showToast(
                this.$t("socialAlreadyConnected"),
                this.$t("socialAlreadySuccess"),
                "warning",
                "AlertCircleIcon"
              );
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.div-user-size {
  width: 237px
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-icon-button-oauth {
  background: white !important;
  box-shadow: none !important;
  width: 100%;
  color: black !important;
  display: flex;
  align-items: center;
  height: 36px;
  justify-content: center;

  .img-google {
    height: 15px;
    widows: 15px;
    margin-right: 0.7em;
  }

}
.mb-05 {
  margin-bottom: 0.5em;
}
.hr-oauth {
  border-top: 1px solid rgb(206, 212, 218);
  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
  >span {
    position: absolute;
    background-color: #f6f6f6;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.6em;
    font-family: 'avenir-medium';
  }
}
.box-shadow-footer-sidebar {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.flex-grow {
  flex-grow: 1;
}
.edit-avatar {
  width: 8rem;
  height: 8rem;
}
.network-item-button-oauth {
  height: 36px !important;
  
  .background-color-net {
    border-radius: 0.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-grow: 1 !important;
    border-right: 1px solid #cccccc60;
  }
  .icon-twitter-profile-connection {
    width: 17px !important;
    height: 17px !important;
    margin: 0.7em;
  }
}
.network-item-list-manage {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  // {height: 75px;
  height: auto;
  min-height: 75px;
  // height: 100%;

  // height: 100% !important;
  border-radius: 1em;
  overflow: hidden;
  display: flex;

  @media(max-width: 575px) {
    height: auto;
  }
  @media(min-width: 576px) {
    border: 1px solid #ccc !important;
  }
  .success-pill-badge {
    color: rgb(6, 150, 6) !important;
    background-color: rgba(0, 128, 0, 0.122) !important;
  }
  .danger-pill-badge {
    color: rgb(116, 17, 17) !important;
    background-color: rgba(255, 0, 0, 0.102) !important;
  }
  .button-icon-actions-nets {
    cursor: pointer;
    padding: 0.5em;
    width: auto;
    height: auto;
    &:hover {
      transition: all 300ms;
      background-color: rgb(225, 225, 225);
      border-radius: 0.5em;
    }
    .icon-actions-nets {
      height: 20px;
      width: 20px;
      
    }
  }
  .background-color-net {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cccccc60;
  }
  .no-border-radius {
    border-radius: 0% !important;
    font-size: 30px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }
  .icon-profile-connection {
    width: 30px !important;
    height: 30px !important;
    margin: 0em 2em 
  }
  .icon-twitter-profile-connection {
    width: 35px !important;
    height: 35px !important;
  }
  .icon-network-profile-connection {
    font-size: 24px;
    color: white;
  }
  .icon-network-profile-connection-13 {
    font-size: 15px;
    color: white;
  }
}

.size-label-inputs > label {
  font-size: 14px;
  font-family: 'avenir-medium';
}
.collapse-extra-info {
  white-space: pre-wrap;
  background-color: #eeeeee;
  padding: 0.5em;
  border-radius: 0.7em;
  border-bottom: 1em;
  font-family: 'avenir-medium';
}
.cleave-followers-add-network {
  height: 45px;
  font-size: 16px;
}
.btn-position {
  margin-top: 10px;
}
.text-network-sider {
  margin-top: 1.5em;
  font-size: 0.85rem;
  color: #495057;
  font-family: 'avenir-medium';
}
.btn-network {
  display: flex;
  margin: 5px;
  align-items: center;
  background: transparent !important;
  border: 0.0625rem solid rgba(222, 226, 230, 0.7);
  flex-direction: column !important;
}
.btn-network:hover {
  color: black;
  box-shadow: rgba(0, 0, 0, 0) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.text-sider-why{
  font-size: 14px;
  cursor: pointer;
}
</style>